import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

const freelancerMenuItems = [
  {
    heading: 'General',
    items: [
      {
        name: 'Projects',
        icon: AccountTreeTwoToneIcon,
        items: [
          {
            name: 'Project Invitations',
            link: '/invitations'
          },
          {
            name: 'Active Projects',
            link: '/projects'
          }
        ]
      },
      {
        name: 'Payouts',
        icon: StorefrontTwoToneIcon,
        link: '/payouts'
      },
      {
        name: 'Tracker',
        icon: AnalyticsTwoToneIcon,
        link: '/download-tracker'
      },
    ]

  }];

export default freelancerMenuItems;
