import {
  Box,
  Typography,
  Container,
  Button,
  styled,
  Snackbar
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@mui/material/Alert';
import { useState, forwardRef } from 'react';
import EmailVerificationService from './emailVerificationService'
import Cookies from 'js-cookie';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const VerifyEmail = () => {
  const { t } = useTranslation();
  const [errorSnack, setErrorSnack] = useState(false);
  const [successSnack, setSuccessSnack] = useState(false);

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnack(false)
    setSuccessSnack(false)
  };

  const checkEmailVerification = async () => {

    if (await EmailVerificationService.checkEmailVerification()) {
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search);
      window.location.href = params.get("next") ? params.get("next") : "/"
    }
    else setErrorSnack(true)
  }

  const sendNewToken = async () => {
    if (await EmailVerificationService.sendNewToken(Cookies.get("email"))) setSuccessSnack(true)
    else console.log("Error occured")
  }

  return (
    <>
      <Helmet>
        <title>Verify Email</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <Typography
              variant="h2"
              sx={{
                my: 2
              }}
            >
              {t("To proceed further please verify your Email by clicking on the link sent to your Email")}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                mb: 4
              }}
            >
              {t(
                "Click below to send the link again."
              )}
            </Typography>
          </Box>
          <div align='center'>
            <Button variant="outlined" sx={{ mr: 2 }} endIcon={<SendIcon />} onClick={sendNewToken}>
              {t('Send again')}
            </Button>

            <Button variant="contained" onClick={checkEmailVerification}>
              {t('Check verification')}
            </Button>
          </div>
        </Container>
      </MainContent>
      <Snackbar open={errorSnack} autoHideDuration={6000} onClose={(e, r) => closeSnack(e, r)}>
        <Alert onClose={(e, r) => closeSnack(e, r)} severity="error" sx={{ width: '100%' }}>
          Not verified yet!
        </Alert>
      </Snackbar>

      <Snackbar open={successSnack} autoHideDuration={6000} onClose={(e, r) => closeSnack(e, r)}>
        <Alert onClose={(e, r) => closeSnack(e, r)} severity="success" sx={{ width: '100%' }}>
          New token is sent!
        </Alert>
      </Snackbar>
    </>
  );
}

export default VerifyEmail;
