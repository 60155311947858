import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login/Cover';
import { useSearchParams } from "react-router-dom";
import VerifyEmail from 'src/content/verifyEmail/verifyEmail';

const Authenticated = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }

  if (!auth.user || !auth.user.is_verified) {
    if (location.pathname !== requestedLocation) setRequestedLocation(location.pathname);
    return <VerifyEmail />
  }


  if (searchParams.get("next")) return <Navigate to={searchParams.get("next")} />

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
