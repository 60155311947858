import { useState } from "react";
import * as Yup from "yup";

import { Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";

import {
    Box,
    Button,
    FormHelperText,
    TextField,
    Link,
    CircularProgress,
    InputLabel,
    FormControl,
    OutlinedInput,
    IconButton,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

import useAuth from "src/hooks/useAuth";
import useRefMounted from "src/hooks/useRefMounted";
import { useTranslation } from "react-i18next";
import GoogleAuthButton from "../../../../components/Buttons/GoogleAuthButton";
import Cookies from "js-cookie";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginJWT = () => {
    const { mrkt365login, googleInitialize } = useAuth();
    const isMountedRef = useRefMounted();
    const { t } = useTranslation();

    const [toShowPassword, setToShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setToShowPassword(!toShowPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
                terms: true,
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email(t("The email provided should be a valid email address"))
                    .max(255)
                    .required(t("The email field is required")),
                password: Yup.string().max(255).required(t("The password field is required")),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    Cookies.set("email", values.email);
                    var loginFailed = await mrkt365login(values.email, values.password);

                    if (loginFailed == true) {
                        setErrors({ terms: "Error logging in, please verify your credentials" });
                    }
                    if (isMountedRef.current) {
                        setStatus({ success: false });
                        setSubmitting(false);
                    }
                } catch (err) {
                    console.error(err);
                    setErrors({ terms: "Error logging in, please verify your credentials" });
                    if (isMountedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {/* <div
                        style={{
                            margin: "1rem",
                            paddingBottom: "1rem",
                            borderBottom: "0.1rem solid rgba(0, 0, 0, 0.05)",
                        }}
                    >
                        <GoogleAuthButton
                            buttonText="Sign in with Google"
                            customOnClick={() => {
                                googleInitialize();
                            }}
                        />
                    </div> */}
                    <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        margin="normal"
                        helperText={touched.email && errors.email}
                        label={t("Email address")}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    {/* <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    margin="normal"
                    helperText={touched.password && errors.password}
                    label={t("Password")}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                /> */}
                    <FormControl sx={{ width: "100%", mt: 2, mb: 2 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={toShowPassword ? "text" : "password"}
                            helperText={touched.password && errors.password}
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {toShowPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            required
                        />
                    </FormControl>
                    {/* <Box alignItems="center" display="flex" justifyContent="flex-end">
                        <Link component={RouterLink} to="/forgot-password">
                            <b>{t("Lost password?")}</b>
                        </Link>
                    </Box> */}

                    {Boolean(touched.terms && errors.terms) && <FormHelperText error>{errors.terms}</FormHelperText>}
                    {/* <Button
          sx={{
            mt: 3,
          }}
          color="primary"
          disabled={isSubmitting}
          fullWidth
          size="large"
          variant="contained"
          onClick={() => googleInitialize()}
        >
          {t("Sign in with Google")}
        </Button> */}
                    <Button
                        sx={{
                            mt: 3,
                        }}
                        color="primary"
                        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                        disabled={isSubmitting}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                    >
                        {t("Sign in")}
                    </Button>
                </form>
            )}
        </Formik>
    );
};

export default LoginJWT;
