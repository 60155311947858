import { lazy } from "react";
import Authenticated from "src/components/Authenticated";

import BaseLayout from "src/layouts/BaseLayout";
import baseRoutes from "./base";
import CollapsedSidebarLayout from "src/layouts/CollapsedSidebarLayout";

import Loader from "../components/Loader";

const LoginPage = Loader(lazy(() => import("../content/login")));
const RegisterPage = Loader(lazy(() => import('../content/register')));
const VerifyEmailPage = Loader(lazy(() => import("../content/verifyEmail/verifyEmail")));
const EmailVerificationPage = Loader(lazy(() => import("../content/verifyEmail/emailVerification")));
const GoogleLogin = Loader(lazy(() => import("../content/login/googleLogin")));
const Logout = Loader(lazy(() => import("../content/logout")));
const CreateForm = Loader(lazy(() => import("../content/create-form")));
const Dashboard = Loader(lazy(() => import("../content/dashboard")));
const ShowForm = Loader(lazy(() => import("../content/show-form")));
const ReviewForm = Loader(lazy(() => import("../content/review-form")));
const PublishForm = Loader(lazy(() => import("../content/publish-form")));


const router = [
    {
        path: "*",
        element: <BaseLayout />,
        children: baseRoutes,
    },
    {
        path: "google/callback",
        children: [
            {
                element: <GoogleLogin />,
            },
        ],
    },
    // Registeration
    {
        path: 'register',
        element:
            <RegisterPage />
    },
    // verify-email
    {
        path: "verify-email",
        element: (
            <Authenticated>
                <VerifyEmailPage />
            </Authenticated>
        ),
    },
    // email-verification
    {
        path: "email-verification",
        element: <EmailVerificationPage />,
    },
    // Logout
    {
        path: "logout",
        element: <Logout />,
    },
    //create-question
    {
        path: "create-form",
        element: <Authenticated><CollapsedSidebarLayout /></Authenticated>,
        children: [
            {
                path: "",
                element: <CreateForm />,
            },
        ],
    },

    {
        path: "dashboard",
        element: <Authenticated><CollapsedSidebarLayout /></Authenticated>,
        children: [
            {
                path: "",
                element: <Dashboard />,
            },
        ],
    }, {
        path: "show-form",
        element: <Authenticated><CollapsedSidebarLayout /></Authenticated>,
        children: [
            {
                path: ":formId",
                element: <ShowForm />,
            },
        ],
    },
    {
        path: "review-form",
        element: <Authenticated><CollapsedSidebarLayout /></Authenticated>,
        children: [
            {
                path: ":formId",
                element: <ReviewForm />,
            },
        ],
    },
    {
        path: "publish-form",
        element: <Authenticated><CollapsedSidebarLayout /></Authenticated>,
        children: [
            {
                path: ":formId",
                element: <PublishForm />,
            },
        ],
    },
];

export default router;
