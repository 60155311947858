import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { userEndpoint } from "../mrkt365config";

const initialAuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const setSession = accessToken => {
    if (accessToken) {
        // localStorage.setItem('accessToken', accessToken);
        Cookies.set("accessToken", accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        // localStorage.removeItem('accessToken');
        Cookies.remove("accessToken");
        delete axios.defaults.headers.common.Authorization;
    }
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    MRKT365LOGIN: (state, action) => {
        const { user } = action.payload;
        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;
        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
    LOGOUT: state => ({
        ...state,
        isAuthenticated: false,
        user: null
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;
        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialAuthState,
    method: "JWT",
    mrkt365login: () => Promise.resolve(),
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    registerViaGoogleInitialize: () => Promise.resolve(),
    registerGoogleFinalize: () => Promise.resolve(),
    googleInitialize: () => Promise.resolve(),
    googleFinalize: () => Promise.resolve(),
});

export const AuthProvider = props => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        const initialize = async () => {
            try {
                // const accessToken = window.localStorage.getItem('accessToken');
                const accessToken = Cookies.get("accessToken");
                const authHeader = { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } };

                if (accessToken) {
                    setSession(accessToken);
                    var user;
                    await fetch(userEndpoint + "users/me", authHeader)
                        .then(res => res.json())
                        .then(
                            res => {
                                user = res;
                            },
                            er => {
                                console.log(er);
                            },
                        );

                    if (user.user_type) {
                        dispatch({
                            type: "INITIALIZE",
                            payload: {
                                isAuthenticated: true,
                                user 
                            },
                        });
                    } else if (user.detail === "Forbidden") {
                        dispatch({
                            type: "INITIALIZE",
                            payload: {
                                isAuthenticated: true,
                            },
                        });
                    } else {
                        dispatch({
                            type: "INITIALIZE",
                            payload: {
                                isAuthenticated: false,
                                user: null,
                            },
                        });
                    }
                } else {
                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        user: null
                    },
                });
            }
        };
        initialize();
    }, []);

    const mrkt365login = async (username, password) => {
        setSession(0);
        var requestOptions = {
            method: "POST",
            headers: {
                accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `username=${username}&password=${password}`,
        };
        var loginFailed = false;
        try {
            await fetch(userEndpoint + "auth/jwt/login", requestOptions)
                .then(res => res.json())
                .then(
                    (res) => {
                        requestOptions = {
                            headers: {
                                accept: "application/json",
                                Authorization: `Bearer ${res.access_token}`,
                            },
                        };
                        if (res.detail === "LOGIN_BAD_CREDENTIALS") {
                            loginFailed = true;
                        }
                        setSession(res.access_token);
                        Cookies.set("email", username);
                    },
                    error => {
                        console.log(error);
                    },
                );
            getuserDetails(loginFailed);
        } catch (e) {
            console.log(e);
        }

        return loginFailed;
    };

    const getuserDetails = async loginFailed => {
        if (loginFailed) {
            dispatch({
                type: "INITIALIZE",
                payload: {
                    isAuthenticated: false,
                    user: null
                },
            });
            return;
        }
        var user;
        const authHeader = { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } };
        await fetch(userEndpoint + "users/me", authHeader)
            .then(res => res.json())
            .then(res => {
                user = res;
            });
        if (user.user_type) {
            dispatch({
                type: "INITIALIZE",
                payload: {
                    isAuthenticated: true,
                    user
                },
            });
        }
        else if (user.detail === "Forbidden") {
            dispatch({
                type: "MRKT365LOGIN",
                payload: {
                    user
                },
            });
        } else if (user.detail === "Forbidden") {
            dispatch({
                type: "MRKT365LOGIN",
                payload: {
                    user
                },
            });
        }
    };

    const login = async (email, password) => {
        // const response = await axios.post('/api/account/login', {
        //   email,
        //   password
        // });
        const user = {
            id: "1",
            avatar: "/static/images/avatars/3.jpg",
            location: "San Francisco, USA",
            username: "admin",
            email: "demo@example.com",
            name: "Randy Smith",
            jobtitle: "Lead Developer",
            password: "TokyoPass1@",
            role: "admin",
            posts: "27",
        };

        // setSession(accessToken);
        dispatch({
            type: "LOGIN",
            payload: {
                user,
            },
        });
    };

    const logout = async () => {
        setSession(null);
        dispatch({ type: "LOGOUT" });
    };

    const register = async (email, name, password) => {
        const response = await axios.post("/api/account/register", {
            email,
            name,
            password,
        });
        const { accessToken, user } = response.data;

        window.localStorage.setItem("accessToken", accessToken);
        dispatch({
            type: "REGISTER",
            payload: {
                user,
            },
        });
    };


    const registerViaGoogleInitialize = async () => {
        setSession(0);
        var requestOptions = {
            method: "GET",
            headers: {
                accept: "application/json",
            },
        };
        try {
            //   console.log("sending request for getting authorization url for registering using google");

            //   var current_origin_prefix = "https://qa.platform.mrkt365.com";

            //   if (window.location.host == "qa.platform.mrkt365.com") {
            //     current_origin_prefix = window.location.origin;
            //   } else if (window.location.host == "platform.mrkt365.com") {
            //     current_origin_prefix = window.location.origin;
            //   }
            await fetch(
                userEndpoint +
                "auth/google/authorize?authentication_backend=jwt&scopes=email&scopes=profile&action=register",
                requestOptions
            )
                .then((res) => res.json())
                .then(
                    (res) => {
                        // console.log("response from google register url request : ", res);
                        // console.log(`result of google authorize initiliaze register api :L `, res);
                        var provided_authorization_url = res["authorization_url"];

                        //  var list_of_uri_splitted = provided_authorization_url.split("&redirect_uri=");
                        //  console.log("list of uri splitted : ", list_of_uri_splitted);
                        //  var list_of_redirect_uri_splitted = list_of_uri_splitted[1].split("&state=");
                        //  var custom_redirect_uri =
                        //    encodeURIComponent(current_origin_prefix + "/google/callback/register") +
                        //    "&state=" +
                        //    list_of_redirect_uri_splitted[1];
                        //  console.log("custom redirect uri : ", custom_redirect_uri);
                        //  var custom_authorization_uri =
                        //    list_of_uri_splitted[0] + "&redirect_uri=" + custom_redirect_uri;
                        //  console.log("custom authorization uri : ", custom_authorization_uri.toString());
                        //  window.open(custom_authorization_uri, "_self");

                        // console.log(`provided authorization url : `, provided_authorization_url);

                        window.open(provided_authorization_url, "_self");
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        } catch (e) {
            console.log(e);
        }
    };

    const registerGoogleFinalize = async query => {
        var loginFailed = false;
        setSession(0);
        // console.log("calling register google finalize api");
        await axios
            .get(userEndpoint + "auth/google/callback" + query + "&action=register")
            .then((res) => {
                if (res.data.detail === "LOGIN_BAD_CREDENTIALS") {
                    loginFailed = true;
                };
                // console.log(`the response for google authorize finalize register api : `, res);
                setSession(res.data.access_token);
                getuserDetails(loginFailed);
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
        return loginFailed;
    };

    const googleInitialize = async () => {
        setSession(0);
        var requestOptions = {
            method: "GET",
            headers: {
                accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        try {
            var current_origin_prefix = "https://qa.platform.mrkt365.com";

            if (window.location.host == "qa.platform.mrkt365.com") {
                current_origin_prefix = window.location.origin;
            } else if (window.location.host == "platform.mrkt365.com") {
                current_origin_prefix = window.location.origin;
            }
            await fetch(userEndpoint + "auth/google/authorize?authentication_backend=jwt&scopes=email&scopes=profile&action=login", requestOptions)
                .then(res => res.json())
                .then(
                    res => {
                        // console.log(`the response for google authorize initiliaze register api : `, res);
                        // window.open(res.authorization_url, "_self");

                        //   console.log(`result of google authorize initiliaze register api :L `, res);
                        var provided_authorization_url = res["authorization_url"];

                        //   var list_of_uri_splitted = provided_authorization_url.split("&redirect_uri=");
                        //   console.log("list of uri splitted : ", list_of_uri_splitted);
                        //   var list_of_redirect_uri_splitted = list_of_uri_splitted[1].split("&state=");

                        //   var custom_redirect_uri =
                        //     encodeURIComponent(current_origin_prefix + "/google/callback") +
                        //     "&state=" +
                        //     list_of_redirect_uri_splitted[1];
                        //   console.log("custom redirect uri : ", custom_redirect_uri);
                        //   var custom_authorization_uri =
                        //     list_of_uri_splitted[0] + "&redirect_uri=" + custom_redirect_uri + "+openid";
                        //   console.log("custom authorization uri : ", custom_authorization_uri.toString());
                        //   window.open(custom_authorization_uri, "_self");

                        window.open(provided_authorization_url, "_self");
                    },
                    error => {
                        console.log(error);
                    },
                );
        } catch (e) {
            console.log(e);
        }
    };

    const googleFinalize = async query => {
        var loginFailed = false;
        setSession(0);
        await axios
            .get(userEndpoint + "auth/google/callback" + query + "&action=login")
            .then(res => {
                if (res.detail === "LOGIN_BAD_CREDENTIALS") loginFailed = true;
                // console.log(`the response for google authorize finalize register api : `, res);
                setSession(res.data.access_token);
                if (res.data.detail) {
                    return Promise.reject(res.data.detail);
                }
                getuserDetails(loginFailed);
            })
            .catch(error => {
                console.log(error);
                return Promise.reject("Google authentication failed");
            });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "JWT",
                mrkt365login,
                login,
                logout,
                register,
                registerViaGoogleInitialize,
                registerGoogleFinalize,
                googleInitialize,
                googleFinalize,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContext;
