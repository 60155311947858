import { Box, List, ListItem, ListItemText, Menu, MenuItem, styled } from "@mui/material";
import { useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import AuthContext from "src/contexts/JWTAuthContext";
import "./menu-styles.css";

const ListWrapper = styled(Box)(
    ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(["color", "fill"])};
            width: auto;
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 0.3rem;
                            width: 1.5rem;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -0.5rem;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
    const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    const project_ref = useRef(null);
    const payout_ref = useRef(null);
    const teams_ref = useRef(null);
    const [isProjectOpen, setProjectOpen] = useState(false);
    const [isPayoutOpen, setPayoutOpen] = useState(false);
    const [isTeamsOpen, setTeamsOpen] = useState(false);

    const handleProjectOpen = () => {
        setProjectOpen(true);
    };

    const handleProjectClose = () => {
        setProjectOpen(false);
    };

    const handlePayoutOpen = () => {
        setPayoutOpen(true);
    };

    const handlePayoutClose = () => {
        setPayoutOpen(false);
    };

    const handleTeamsOpen = () => {
        setTeamsOpen(true);
    };

    const handleTeamsClose = () => {
        setTeamsOpen(false);
    };

    const userType = user?.user_type || 0;

    return (
        <div className="menu-holding-container">
            {userType === 1 || userType === 4 ? (
                <ListWrapper>
                    <List disablePadding component={Box} display="flex">
                        <ListItem
                            classes={{ root: "MuiListItem-indicators" }}
                            button
                            component={NavLink}
                            to={`/my-projects`}
                        >
                            <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t("Projects")} />
                        </ListItem>
                        <ListItem
                            classes={{ root: "MuiListItem-indicators" }}
                            button
                            component={NavLink}
                            to={`/marketplace`}
                        >
                            <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t("Marketplace")} />
                        </ListItem>
                        {userType != 4 ? (
                            <ListItem
                                classes={{ root: "MuiListItem-indicators" }}
                                button
                                component={NavLink}
                                to={`/payments`}
                            >
                                <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t("Payments")} />
                            </ListItem>
                        ) : null}
                        <ListItem
                            classes={{ root: "MuiListItem-indicators" }}
                            button
                            ref={teams_ref}
                            onClick={handleTeamsOpen}
                        >
                            <ListItemText
                                primaryTypographyProps={{ noWrap: true }}
                                primary={
                                    <Box display="flex" alignItems="center">
                                        {t("Teams")}
                                        <Box display="flex" alignItems="center" pl={0.3}>
                                            <ExpandMoreTwoToneIcon fontSize="small" />
                                        </Box>
                                    </Box>
                                }
                            />
                        </ListItem>
                        {userType != 4 ? (
                            <ListItem
                                classes={{ root: "MuiListItem-indicators" }}
                                button
                                onClick={() => {
                                    window.open("https://affiliate.mrkt365.com/affiliates/signup.php#SignupForm");
                                }}
                            >
                                <ListItemText
                                    primaryTypographyProps={{ noWrap: true }}
                                    primary={t("Affiliate Program")}
                                />
                            </ListItem>
                        ) : null}
                        {userType == 1 ? (
                            <ListItem
                                classes={{ root: "MuiListItem-indicators" }}
                                button
                                component={NavLink}
                                to={`/business-onboarding`}
                            >
                                <ListItemText
                                    primaryTypographyProps={{ noWrap: true }}
                                    primary={t("Business Listing")}
                                />
                            </ListItem>
                        ) : null}
                    </List>
                    <Menu disableScrollLock anchorEl={teams_ref.current} onClose={handleTeamsClose} open={isTeamsOpen}>
                        <MenuItem component={NavLink} to="/manage-team">
                            {t("Manage Teams")}
                        </MenuItem>
                        <MenuItem component={NavLink} to="/marketplace?saved-profiles=true">
                            {t("Saved Profiles")}
                        </MenuItem>
                    </Menu>
                </ListWrapper>
            ) : (
                <ListWrapper>
                    <List disablePadding component={Box} display="flex">
                        <ListItem
                            classes={{ root: "MuiListItem-indicators" }}
                            button
                            component={NavLink}
                            to={`/dashboard`}
                        >
                            <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t("Dashboard")} />
                        </ListItem>
                        <ListItem
                            classes={{ root: "MuiListItem-indicators" }}
                            button
                            ref={project_ref}
                            onClick={handleProjectOpen}
                        >
                            <ListItemText
                                primaryTypographyProps={{ noWrap: true }}
                                primary={
                                    <Box display="flex" alignItems="center">
                                        {t("Projects")}
                                        <Box display="flex" alignItems="center" pl={0.3}>
                                            <ExpandMoreTwoToneIcon fontSize="small" />
                                        </Box>
                                    </Box>
                                }
                            />
                        </ListItem>
                        {userType != 5 ? (
                            <ListItem
                                classes={{ root: "MuiListItem-indicators" }}
                                button
                                component={NavLink}
                                to={`/payouts`}
                            >
                                <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t("Payouts")} />
                            </ListItem>
                        ) : null}
                        <ListItem
                            classes={{ root: "MuiListItem-indicators" }}
                            button
                            component={NavLink}
                            to={`/download-tracker`}
                        >
                            <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t("Tracker")} />
                        </ListItem>
                    </List>
                    <Menu
                        disableScrollLock
                        anchorEl={project_ref.current}
                        onClose={handleProjectClose}
                        open={isProjectOpen}
                    >
                        <MenuItem component={NavLink} to="/invitations">
                            {t("Project Invitations")}
                        </MenuItem>
                        <MenuItem component={NavLink} to="/projects">
                            {t("Active Projects")}
                        </MenuItem>
                    </Menu>
                    <Menu
                        disableScrollLock
                        anchorEl={payout_ref.current}
                        onClose={handlePayoutClose}
                        open={isPayoutOpen}
                    >
                        <MenuItem component={NavLink} to="/payouts-summary">
                            {t("Payouts Summary")}
                        </MenuItem>
                        <MenuItem component={NavLink} to="/payoneer-support">
                            {t("Payoneer Support")}
                        </MenuItem>
                    </Menu>
                </ListWrapper>
            )}
        </div>
    );
}

export default HeaderMenu;
