import axios from 'axios';
import { userEndpoint } from 'src/mrkt365config';
import Cookies from 'js-cookie'

class EmailVerificationService {
    static async verifyEmail(token) {
        var verificationSuccessfull;
        await axios.post(userEndpoint + "auth/verify", { "token": token })
            .then((res) => {
                if (res.status !== 200) verificationSuccessfull = false;
                else verificationSuccessfull = true
            }).catch((er) => {
                verificationSuccessfull = false
            })
        return verificationSuccessfull;
    }

    static async sendNewToken(email) {
        var emailSentSuccessfully;
        await axios.post(userEndpoint + "auth/request-verify-token", { "email": email })
            .then((res) => {
                console.log(res)
                if (res.status !== 202) emailSentSuccessfully = false;
                else emailSentSuccessfully = true
            }).catch((er) => { emailSentSuccessfully = false })

        return emailSentSuccessfully;
    }

    static async checkEmailVerification() {
        var emailIsVerified;
        await axios.get(userEndpoint + "users/me", { headers: { 'Authorization': `Bearer ${Cookies.get("accessToken")}` } })
            .then((res) => {
                if (res.status === 200) emailIsVerified = true
                else emailIsVerified = false
            }).catch((er) => {
                emailIsVerified = false
            })
        return emailIsVerified;
    }
}

export default EmailVerificationService;