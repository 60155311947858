import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

const businessMenuItems = [
    {
        heading: 'General',
        items: [
            {
                name: 'Projects',
                icon: AccountTreeTwoToneIcon,
                link: '/my-projects'
            },
            {
                name: 'Marketplace',
                icon: StorefrontTwoToneIcon,
                link: '/marketplace'
            },
            {
                name: 'Payments',
                icon: ReceiptTwoToneIcon,
                link: '/payments'
            },
            {
                name: 'Teams',
                icon: AccountTreeTwoToneIcon,
                items: [
                    {
                        name: 'Manage Team',
                        link: '/manage-team'
                    },
                    {
                        name: 'Saved Profiles',
                        link: '/marketplace?saved-profiles=true'
                    }
                ]
            },
            {
                name: 'Affiliate Program',
                icon: BackupTableTwoToneIcon,
                link: 'https://affiliate.mrkt365.com/affiliates/signup.php#SignupForm'
            },
            {
                name: 'Business Listing',
                icon: AssignmentIndTwoToneIcon,
                link: '/business-onboarding'
            },
        ]

    }];

export default businessMenuItems;
