import ReactDOM from "react-dom";
import "src/utils/chart";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "src/hooks/useScrollTop";
import "nprogress/nprogress.css";
import { Provider } from "react-redux";
import store from "src/store";
import App from "src/App";
import { SidebarProvider } from "src/contexts/SidebarContext";
import * as serviceWorker from "src/serviceWorker";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import reportWebVitals from "./reportWebVitals";
import { env, trueProductionFlag } from "./mrkt365config";
// import TagManager from "react-gtm-module";

// var test_env_google_tag_id = "GTM-NDZZTLG";
// var test_env_google_tag_env_ga_auth = "rzhzSizGcZE_85PnEnK9Eg";
// var test_env_google_tag_env_ga_preview = "env-5";

// following object needs to be updated with real production ga_auth and ga_env

// var tagManagerArgs = {};

// if (env === "development") {
//   tagManagerArgs["gtmId"] = test_env_google_tag_id;
//   tagManagerArgs["gtmAuth"] = test_env_google_tag_env_ga_auth;
//   tagManagerArgs["gtmPreview"] = test_env_google_tag_env_ga_preview;
// }
// if (trueProductionFlag == true) {
//     tagManagerArgs["gtmId"] = "GTM-W3KBPT8";
// }

// console.log(tagManagerArgs);
// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
            <SidebarProvider>
                <BrowserRouter>
                    <ScrollTop />
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </SidebarProvider>
        </Provider>
    </HelmetProvider>,
    document.getElementById("root")
);

reportWebVitals(console.log);
serviceWorker.unregister();
