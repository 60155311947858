export const env = process.env.NODE_ENV

const host =
    window.location.host === "customer-service.mrkt365.com"
        ? "api-customerservice"
        : window.location.host === "customer-service.mrkt365.com"
            ? "api-customerservice"
            : "qa-api-customerservice";
        
const localHost = "https://api-customerservice.mrkt365.com/";
// const localHost = "http://localhost/";
const HOST_URL = "https://" + host + ".mrkt365.com/";

export const CURRENT_URL = (env === "development" ? "http://" : "https://") + window.location.host + "/";

export const PLATFORM_URL = (env === "development" ? "http://" : "https://") + (host === "api-customerservice" ? "customer-service.stage.mrkt365.com" : "qa-customer-service.mrkt365.com") + "/"

// mrkt365user
const DEV_MRKT365User_Endpoint = localHost + "mrkt365user/api/v1/";
const MRKT365User_Endpoint = HOST_URL + "mrkt365user/api/v1/";

// mrkt365vendor
const DEV_MRKT365InternProgram_Endpoint = localHost + "mrkt365internprogram/api/v1/";
const MRKT365InternProgram_Endpoint = HOST_URL + "mrkt365internprogram/api/v1/";


export const userEndpoint = env === "development" ? DEV_MRKT365User_Endpoint : MRKT365User_Endpoint;
export const internProgramEndpoint = env === "development" ? DEV_MRKT365InternProgram_Endpoint : MRKT365InternProgram_Endpoint;
