import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/">
      {isMobile ? (<img
        src={`/mrkt365logo_small.png`}
        alt={"mrkt365 logo"} width="100px"
      />) : (<img
        src={`/mrkt365logo_small.png`}
        alt={"mrkt365 logo"}
      />)}
    </LogoWrapper>
  );
}

export default Logo;
