import React, { useMemo } from 'react';
import useAuth from 'src/hooks/useAuth';
// import { fetchCountryList } from "src/services/users"
import useApiCaller from "src/hooks/useApiCaller";

const AppContext = React.createContext({});

// const attachUserCountry = (userData, countryList = []) => {
//     if (countryList.length && userData?.country_id) {
//         const userCountry = countryList.find(val => val.id === userData.country_id);
//         return { ...userData, country: userCountry?.name };
//     }
//     return userData
// }

export const AppContextProvider = ({ children }) => {
    const {
        isAuthenticated,
        isInitialized,
        user,
        basic_info
    } = useAuth();

    // const {
    //     data: countriesList
    // } = useApiCaller(fetchCountryList, {});

    // const formattedUserInfo = useMemo(() => attachUserCountry(user, countriesList), [user?.country_id, countriesList.length]);
    return (<AppContext.Provider value={{ isAuthenticated, isInitialized, userDetails: user, vendorDetails: basic_info }}>
        {children}
    </AppContext.Provider>);
};

export default AppContext;
